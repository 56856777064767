<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    collapse?: boolean
    horizontalScroll?: boolean
  }>(),
  {
    collapse: true,
  },
)

const app = useAppConfig()

const route = useRoute()

const showNavBurger = computed(() => {
  return props.collapse && app.tairo.collapse?.toolbar?.showNavBurger
})
</script>

<template>
  <div
    class="relative z-50 flex h-16 items-center gap-2"
    :class="[
      props.horizontalScroll && 'pe-4 xl:pe-10',
      route.meta.title === 'Adjusters' ? 'mb-2.5 md:mb-5' : 'mb-5',
    ]"
  >
    <TairoCollapseBurger v-if="showNavBurger" class="-ms-3" />

    
    <BaseHeading
      v-if="app.tairo.collapse?.toolbar?.showTitle"
      as="h1"
      size="2xl"
      weight="normal"
      class="dark:text-muted-100 cursor-default text-gray-900 md:hidden"
    >
      <slot v-if="route.meta.title === 'Insurance Companies'" name="title"
        >Companies</slot
      >
      <slot v-else-if="route.meta.title === 'Landing'" name="title"
        >Dashboard</slot
      >
      <slot v-else name="title">{{ route.meta.title }}</slot>
    </BaseHeading>

    
    <BaseHeading
      v-if="app.tairo.collapse?.toolbar?.showTitle"
      as="h1"
      size="2xl"
      weight="normal"
      class="dark:text-muted-100 hidden cursor-default text-gray-900 md:block"
    >
      <slot v-if="route.meta.title === 'Landing'" name="title">Dashboard</slot>
      <slot v-else name="title">{{ route.meta.title }}</slot>
    </BaseHeading>

    
    <div
      v-if="route.meta.title === 'Adjusters'"
      class="mx-5 hidden h-7 w-[1px] border-l border-slate-300 md:block"
    ></div>
    <a
      v-if="route.meta.title === 'Adjusters'"
      @click="$router.push('/insurance-companies')"
      class="font-heading hidden cursor-pointer md:block"
    >
      <Icon
        name="material-symbols:keyboard-arrow-down"
        class="dark:text-muted-500 mr-2.5 block h-6 w-6 rotate-90 text-slate-400"
      />
      <span
        class="dark:text-muted-100 inline-block pt-2 text-sm font-medium text-gray-900"
      >
        Back to Insurance Companies</span
      ></a
    >
    <div
      v-if="route.meta.title === 'Users'"
      class="mx-5 hidden h-7 w-[1px] border-l border-slate-300 md:block"
    ></div>
    <a
      v-if="route.meta.title === 'Users'"
      @click="$router.push('/organization')"
      class="font-heading hidden cursor-pointer md:block"
    >
      <Icon
        name="material-symbols:keyboard-arrow-down"
        class="dark:text-muted-500 mr-2.5 block h-6 w-6 rotate-90 text-slate-400"
      />
      <span
        class="dark:text-muted-100 inline-block pt-2 text-sm font-medium text-gray-900"
      >
        Back to Organizations</span
      ></a
    >

    <div
      v-if="route.meta.title === 'Total'"
      class="mx-5 hidden h-7 w-[1px] border-l border-slate-300 md:block"
    ></div>
    <a
      v-if="route.meta.title === 'Total'"
      @click.once="$router.go(-1)"
      class="font-heading hidden cursor-pointer md:block"
    >
      <Icon
        name="material-symbols:keyboard-arrow-down"
        class="dark:text-muted-500 mr-2.5 block h-6 w-6 rotate-90 text-slate-400"
      />
      <span
        class="dark:text-muted-100 inline-block pt-2 text-sm font-medium text-gray-900"
      >
        Back to Contractors</span
      ></a
    >

    <div class="ms-auto"></div>
    <template v-for="tool of app.tairo.collapse?.toolbar?.tools">
      <component
        :is="resolveComponentOrNative(tool.component)"
        v-if="tool.component"
        :key="tool.component"
        v-bind="tool.props"
      />
    </template>
  </div>
  
  <a
    v-if="route.meta.title === 'Adjusters'"
    @click.once="$router.go(-1)"
    class="font-heading mb-5 flex cursor-pointer items-center md:hidden"
  >
    <Icon
      name="material-symbols:keyboard-arrow-down"
      class="dark:text-muted-500 mr-2.5 block h-6 w-6 rotate-90 text-slate-400"
    />
    <span
      class="dark:text-muted-100 inline-block text-sm font-medium text-gray-900"
    >
      Back to Insurance Companies</span
    ></a
  >
  <a
    v-if="route.meta.title === 'Total'"
    @click.once="$router.go(-1)"
    class="font-heading mb-5 flex cursor-pointer items-center md:hidden"
  >
    <Icon
      name="material-symbols:keyboard-arrow-down"
      class="dark:text-muted-500 mr-2.5 block h-6 w-6 rotate-90 text-slate-400"
    />
    <span
      class="dark:text-muted-100 inline-block text-sm font-medium text-gray-900"
    >
      Back to Contractors</span
    ></a
  >
</template>

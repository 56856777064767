<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/store/auth.js'
import { useCollapse } from '../composables/collapse'

const store = useAuthStore()
const { permissions } = storeToRefs(store)
const hasGroupsPermission = permissions.value?.includes('groups:read')
const hasUsersPermission = permissions.value?.includes('user:read')
const config = useRuntimeConfig()
const featureFlagEnabled = config.public.featureFlag

const { isOpen, isMobileOpen, menuItems } = useCollapse()
const app = useAppConfig()

const menuItemsComputed = computed(() => {
  let itemsToReturn
  const clonedMenuItems = JSON.parse(JSON.stringify(menuItems.value))
  const menuItemWithChildren = clonedMenuItems.find((item) =>
    Array.isArray(item.children),
  )
  if (menuItemWithChildren) {
    menuItemWithChildren.children = menuItemWithChildren.children.filter(
      (item) => item.name !== 'Groups' && item.name !== 'Users',
    )
  }

  if (
    hasGroupsPermission &&
    hasUsersPermission &&
    featureFlagEnabled === 'true'
  ) {
    itemsToReturn = menuItems.value
  } else {
    itemsToReturn = clonedMenuItems
  }

  return itemsToReturn
})
console.log(menuItems)
</script>

<template>
  <div
    class="dark:bg-muted-800 border-muted-200 dark:border-muted-700 fixed left-0 top-0 z-[60] flex h-full flex-col border-r bg-white transition-all duration-300"
    :class="[
      !isOpen ? 'w-[80px]' : 'w-[280px]',
      isMobileOpen
        ? 'translate-x-0 lg:translate-x-0'
        : '-translate-x-full lg:translate-x-0',
    ]"
  >
    
    <slot name="header">
      <component
        v-if="app.tairo.collapse?.navigation?.header?.component"
        :is="
          resolveComponentOrNative(
            app.tairo.collapse?.navigation?.header?.component,
          )
        "
      />
    </slot>
    
    <div
      class="slimscroll relative w-full grow overflow-y-auto py-6"
      :class="!isOpen ? 'px-4' : 'px-6'"
    >
      
      <ul id="sidebar-menu" class="space-y-2">
        
        <li v-for="(item, index) in menuItemsComputed" :key="index">
          <TairoCollapseNavigationCollapseLinks
            v-if="item.children"
            :item="item"
            :expanded="isOpen"
            @clicked="isOpen = true"
          />
          <NuxtLink
            v-else-if="item.to"
            :to="item.to"
            exact-active-class=" !text-primary-500 dark:!text-primary-500"
            class="nui-focus dark:text-muted-100 hover:text-primary-500 dark:hover:text-primary-500 flex cursor-pointer items-center gap-5 rounded-lg py-3 font-medium text-slate-400 transition-colors duration-300 focus-within:outline-none dark:focus-within:outline-none"
            :class="!isOpen ? 'px-1 justify-center' : 'px-4'"
            @click="isMobileOpen = false"
          >
            <Icon :name="item.icon.name" :class="item.icon.class" />
            <span
              class="font-heading whitespace-nowrap text-sm"
              :class="!isOpen ? 'hidden' : 'block'"
            >
              {{ item.name }}
            </span>
          </NuxtLink>
          <div
            v-else-if="item.divider"
            class="border-muted-200 dark:border-muted-700 my-3 h-px w-full border-t"
          ></div>
          <button
            v-else
            class="nui-focus dark:text-muted-100 hover:text-primary-500 dark:hover:text-primary-500 flex w-full cursor-pointer items-center gap-5 rounded-lg py-3 text-slate-400 transition-colors duration-300 focus-within:outline-none dark:focus-within:outline-none"
            :class="!isOpen ? 'px-1 justify-center' : 'px-4'"
            @click="item.click"
          >
            <Icon :name="item.icon.name" :class="item.icon.class" />
            <span
              class="font-heading whitespace-nowrap text-sm"
              :class="!isOpen ? 'hidden' : 'block'"
            >
              {{ item.name }}
            </span>
          </button>
        </li>
      </ul>
    </div>
    
    <slot name="footer">
      <component
        v-if="app.tairo.collapse?.navigation?.footer?.component"
        :is="
          resolveComponentOrNative(
            app.tairo.collapse?.navigation?.footer?.component,
          )
        "
      />
    </slot>
  </div>
</template>
